import React, { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import { MatxLoading } from "../components";
import APIKit from "../../config/APIKit";
import {loggin} from '../../store/login';
import {addPermission} from '../../store/permission';
import {useDispatch,useSelector} from 'react-redux';
// import { useNavigate} from 'react-router-dom'
const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = async (accessToken) => {
  if (!accessToken) {
    return false;
  }
  let valid = {
    status: false,
  };
  await APIKit.get("/me").then((res) => {
    if (res) {
      valid = {
        status: true,
        user: res,
      };
    }
  });
  return valid;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDisPatch = useDispatch();
  const userDatas = useSelector(state=>state.logged)
  const login = async (email, password) => {
    const response = await APIKit.post("/login", {
      email,
      password,
    });
    const { token, user } = response.data;
    setSession(token);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
    // reduxDisPatch(loggin(response.data))
    return response.data
  };

  const register = async (email, username, password) => {
    const response = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };
  // const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
          isValidToken(accessToken).then((res) => {
            if (res) {
              const { user } = res;
              const userData = {
                token : accessToken,
                user:user
              }
              reduxDisPatch(loggin(userData))
              setSession(accessToken);

              dispatch({
                type: "INIT",
                payload: {
                  isAuthenticated: true,
                  user,
                },
              });
            } else {
              dispatch({
                type: "INIT",
                payload: {
                  isAuthenticated: false,
                  user: null,
                },
              });
            }
          }).catch(err=>{
            localStorage.clear();
            dispatch({
              type: "INIT",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
            // navigate('/session/signin');
          })
          
        } else {
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, [reduxDisPatch]);
  useEffect(()=>{
    if (userDatas.role) {
      APIKit.get(`/getPermission/${userDatas.role}`).then(res=>{
        if(res.status){
          reduxDisPatch(addPermission(res.data))
        }
      })
    }
  },[userDatas,reduxDisPatch])

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import Loadable from "../../components/Loadable";
import { lazy } from "react";
import { CATEGORY, PERMISSION, ROLE_PERMISSION, USERS } from "../../utils/constant";
const StoreCategory = Loadable(lazy(() => import("./StoreCategory")));
const AddRole = Loadable(lazy(() => import("./AddRole")));
const Permission = Loadable(lazy(() => import("./AddPermission")));
const Users = Loadable(lazy(() => import("./Users")));
const Profile = Loadable(lazy(() => import("./Profile")));
const settingsRoute = [
  {
    path: "/settings/role",
    element: <AddRole />,
    permission: ROLE_PERMISSION,
    // auth:ROLE_PERMISSION
  },
  {
    path: "/settings/permission",
    element: <Permission />,
    permission: PERMISSION,
  },
  {
    path: "/settings/users",
    element: <Users />,
    permission: USERS,
  },
  {
    path: "/settings/category",
    element: <StoreCategory />,
    permission: CATEGORY,
  },
  {
    path: "/settings/profile",
    element: <Profile />
  },
];

export default settingsRoute;

import Loadable from "../../components/Loadable";
import { lazy } from "react";
// import { PERMISSION, ROLE_PERMISSION, USERS } from "../../utils/constant";
const DayList = Loadable(lazy(() => import("./DayList")));
const CustomReport = Loadable(lazy(() => import("./CustomReport")));
const StatusReport = Loadable(lazy(() => import("./StatusReport")));
const StatusWiseReport = Loadable(lazy(() => import("./StatusWiseReport")));
const ExcecutiveWiseReport = Loadable(lazy(() => import("./ExcecutiveWiseReport")));

const reportRoutes = [
  {
    path: "/report/daily",
    element: <DayList />
  },
  {
    path: "/report/report",
    element: <CustomReport/>
  },
  {
    path: "/report/statusReport",
    element: <StatusReport/>
  },
  {
    path: "/report/statusWiseReport",
    element: <StatusWiseReport/>
  },
  {
    path: "/report/ExecutiveWiseReport",
    element: <ExcecutiveWiseReport/>
  },
];
export default reportRoutes;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine";
import { BrowserRouter } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "font-awesome/css/font-awesome.min.css";
import { Provider } from 'react-redux';
import store from "./store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider injectFirst>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <CssBaseline />
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

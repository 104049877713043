import AuthGuard from './auth/AuthGuard';
import chartsRoute from './views/charts/ChartsRoute';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import materialRoutes from './views/material-kit/MaterialRoutes';
import settingsRoute from './views/settings/settingsRoutes';
import NotFound from './views/sessions/NotFound';
import sessionRoutes from './views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import categoryRoute from './views/master/MasterRoute';
// import inventoryRoute from './views/inventory/inventoryroute'; 
import reportRoutes from './views/reports/reportRoutes';
const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute, 
      ...materialRoutes,
      ...settingsRoute,
      ...categoryRoute,
      // ...inventoryRoute,
      ...reportRoutes
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;

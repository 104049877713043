import {createSlice} from '@reduxjs/toolkit';
export const permissionSlice = createSlice({
    name :'Permission',
    initialState :{
        Permission : []
    },
    reducers : {
        addPermission:(state,action)=>{
            state.Permission = action.payload.Permission
        }
    }
})
export const {addPermission} = permissionSlice.actions;
export default permissionSlice.reducer;
import {createSlice} from '@reduxjs/toolkit';
export const loginSlice = createSlice({
    name :'Login',
    initialState : {
        isLogged : false,
        key : '',
        role:'',
        isAdmin:false
    },
    reducers : {
        loggin:(state,action)=>{
            state.isLogged = true;
            state.key = action.payload.token
            state.role = action.payload.user.data.me.role
            state.isAdmin = action.payload.user.data.me.role===1
        },
        logout : (state)=>{
            state = false
        }
    }
})
export const {loggin,logout} = loginSlice.actions;
export default loginSlice.reducer;
// import '../fake-db';
import { useRoutes } from "react-router-dom";
import { MatxTheme } from "./components";
import { AuthProvider } from "./contexts/JWTAuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import routes from "./routes";
import { useSelector } from "react-redux";
import { isGranded } from "./utils/utils";
import SnackBar from "./component/SnackBar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const App = () => {
  const permissions = useSelector((state) => state.config.Permission);
  const { children } = routes[0];
  const content = useRoutes(routes);
  children.forEach((element, index) => {
    if (element.permission && !isGranded(element.permission, permissions)) {
      children.splice(index, 1);
    }
  });
  return (
    <SettingsProvider>
      <MatxTheme>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>{content}</AuthProvider>
        </LocalizationProvider>
      </MatxTheme>
      <SnackBar />
    </SettingsProvider>
  );
};

export default App;

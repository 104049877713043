import {configureStore} from '@reduxjs/toolkit';
import  LogginReduces from './login';
import  PermissionReduces from './permission';
import snackBarSlice from './snackBar';
export default configureStore({
    reducer : {
        logged:LogginReduces,
        config : PermissionReduces,
        snackBar:snackBarSlice
    }
})
import { lazy } from "react";
import Loadable from "../../components/Loadable";
import {SVV} from "../../utils/constant";
const SvvList = Loadable(lazy(() => import("./SvvList")));
const SVVForm = Loadable(lazy(() => import("./SVVForm")));
const SvvStatus = Loadable(lazy(() => import("./SvvStatus")));
const StatusUpdate = Loadable(lazy(() => import("./StatusUpdate")));
const UploadFile = Loadable(lazy(() => import("./UploadFile")));
const AllSvvList = Loadable(lazy(() => import("./AllSvvList")));
const Salary = Loadable(lazy(() => import("./Salary")));
const categoryRoute = [
    {
      path: '/master/svvList',
      element: <SvvList />
    },
    {
      path: '/master/svv/add',
      element: <SVVForm />,
      // permission: CATEGORY
    },
    {
      path: '/master/svv/add/:id',
      element: <SVVForm />,
      // permission: CATEGORY
    },
    {
      path: '/master/svv/status',
      element: <SvvStatus />,
      adminOnly: true
    },
    {
      path: '/master/svv/statusUpdate',
      element: <StatusUpdate />
    },
    {
      path: '/master/svv/svvUpload',
      element: <UploadFile />,
      permission: SVV
    },
    {
      path: '/master/svv/allList',
      element: <AllSvvList />,
      permission: SVV
    },
    {
      path: '/master/svv/salary',
      element: <Salary />,
      permission: SVV
    }
  ];
  
  export default categoryRoute;
export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200
export const ROLE_PERMISSION = 'Role';
export const PERMISSION = 'Permission';
export const USERS = 'Users';
export const CATEGORY = 'Category';
export const UNIT = 'Unit';
export const PRODUCT = 'Product';
export const SVV = 'Svv';
export const ColorCode = [
    '#C0C0C0',
    '#808000',
    '#008080',
    '#800080',
    '#7FFFD4',
    '#FFA500',
    '#00FFFF',
    '#FFC0CB',
    '#1589FF',
    '#38ACEC',
    '#50C878',
    '#6CC417',
    '#C2B280',
    '#FFFFF0'
]
   